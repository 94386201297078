import React from "react";
import PropTypes from "prop-types";

const Icon = ({isActive = false}) => {
    return (
        <svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill={isActive ? "#0071BC" : "#929292"} d="M17.352 7.997a.65.65 0 0 0-.312.118l-9.822 7.023a.52.52 0 0 0-.094.76c.197.22.602.26.849.084l9.435-6.753 9.124 6.745c.239.201.667.18.878-.046a.523.523 0 0 0-.104-.782l-9.502-7.022a.66.66 0 0 0-.452-.127m-8.247 8.095v11.344c0 .283.288.54.604.54 5.032.03 10.064.018 15.097 0 .316 0 .604-.257.604-.54V16.092c0-.283-.288-.54-.604-.54-.317 0-.604.257-.604.54v10.804l-3.623.017v-5.934c0-.283-.288-.54-.604-.54H14.54c-.316 0-.604.257-.604.54v5.934l-3.623-.017V16.092c.005-.289-.302-.527-.613-.55-.293.023-.592.306-.595.55m6.039 5.427h4.227v5.394h-4.227z" />
            <path fill={isActive ? "#F8A03A" : "#929292"} fill-rule="evenodd" clip-rule="evenodd" d="M13.936 26.9h1.208v-5.4h4.227v5.4h-3.75 4.957v-5.97c0-.283-.287-.54-.603-.54H14.54c-.316 0-.604.257-.604.54z" />
        </svg>

    );
};

Icon.propTypes = {
    isActive: PropTypes.bool
};

export default Icon;
