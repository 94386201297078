export const RWC_GA_EVENTS = {
    RWC_BOOKING_INITIATED: {
        event: "custom_event",
        eventCategory: "rwc_booking_initiated",
        eventAction: "lead_id",
        eventLabel: "<LEAD ID from HS>"
    },
    RWC_SECTION_SEEN: {
        event: "custom_event",
        eventCategory: "rwc_section_seen",
        eventAction: "section_name"
    },
    RWC_INSPECTED_SECTION_CLICKED: {
        event: "custom_event",
        eventCategory: "rwc_inspected_section_clicked",
        eventAction: "section_type",
        eventLabel: "<pill name>"
    },
    RWC_LOCATION_CLICKED: {
        event: "custom_event",
        eventCategory: "rwc_location_clicked",
        eventAction: "NA",
        eventLabel: "NA"
    },
    RWC_OTHER_BUSINESS_CLICKED: {
        event: "custom_event",
        eventCategory: "rwc_other_business_clicked",
        eventAction: "section_name",
        eventLabel: "buy/sell/finance"
    },
    RWC_GOOGLE_RATING_CLICKED: {
        event: "custom_event",
        eventCategory: "rwc_google_rating_clicked",
        eventAction: "NA",
        eventLabel: "NA"
    },
    RWC_DETAILS_ENTERED: {
        event: "custom_event",
        eventCategory: "rwc_details_entered",
        eventAction: "detail_name",
        eventLabel: "<Text field name>"
    },
    RWC_BOOKING_CONFIRMED: {
        event: "custom_event",
        eventCategory: "rwc_booking_confirmed",
        eventAction: "lead_id",
        eventLabel: "<LEAD ID from HS>"
    },
    RWC_FAQ_CLICKED: {
        event: "custom_event",
        eventCategory: "rwc_faq_clicked",
        eventAction: "faq_name",
        eventLabel: "<FAQ name>"
    },
    RWC_SECTION_ENTERED: {
        event: "custom_event",
        eventCategory: "rwc_section_entered",
        eventAction: "section_name",
        eventLabel: "sell_hp_banner/home_page_banner/header/bottom_nav_bar"
    }
};
