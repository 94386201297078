import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import BottomNavigation from "./component";

const mapStateToProps = ({
    filters: {
        allFilters,
        selectedFilters
    },
    cities: {
        selectedCity
    },
    user: {
        gaId
    }

}) => ({
    allFilters,
    selectedFilters,
    selectedCity,
    gaId
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BottomNavigation);
