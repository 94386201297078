import React from "react";
import PropTypes from "prop-types";

const Icon = ({isActive = false}) => {
    return (
        <svg width="34" height="32" viewBox="0 0 34 32" fill={isActive ? "#0071BC" : "#929292"} xmlns="http://www.w3.org/2000/svg">
            <path d="M17.7945 11.229H12.6901C12.3769 11.229 12.1299 11.483 12.1299 11.7892C12.1299 12.0989 12.3769 12.3494 12.6901 12.3494H17.7944C18.1076 12.3494 18.3546 12.0954 18.3546 11.7892C18.3547 11.4761 18.1007 11.229 17.7945 11.229Z" fill={isActive ? "#0071BC" : "#929292"}/>
            <path d="M19.8369 14.5936H12.6901C12.3769 14.5936 12.1299 14.8476 12.1299 15.1538C12.1299 15.4635 12.3769 15.714 12.6901 15.714H19.8369C20.1501 15.714 20.3971 15.46 20.3971 15.1538C20.3971 14.8407 20.1431 14.5936 19.8369 14.5936Z" fill={isActive ? "#0071BC" : "#929292"}/>
            <path d="M16.0895 17.9547H12.6901C12.3769 17.9547 12.1299 18.2087 12.1299 18.5149C12.1299 18.828 12.3839 19.0751 12.6901 19.0751H16.0895C16.4027 19.0751 16.6497 18.8211 16.6497 18.5149C16.6497 18.2052 16.3992 17.9547 16.0895 17.9547Z" fill={isActive ? "#0071BC" : "#929292"}/>
            <path d="M24.3041 18.5324V9.48223C24.3041 8.66805 23.6395 8 22.8218 8H11.4822C10.6681 8 10 8.66455 10 9.48223V21.8831C10 22.2727 10.1566 22.652 10.4314 22.9269L12.7349 25.2268C13.0097 25.5017 13.3925 25.6583 13.7787 25.6583H18.9388V27.6937C18.9388 27.913 19.0675 28.1182 19.2728 28.2087C19.4711 28.2992 19.7147 28.2644 19.8748 28.1148L20.9777 27.1266L22.0807 28.1148C22.2721 28.2609 22.5296 28.2609 22.6827 28.2087C22.8949 28.1426 23.0167 27.9165 23.0167 27.6937V24.2386C24.2067 23.5358 25 22.2553 25 20.787C25 19.952 24.7425 19.176 24.3041 18.5324ZM12.8392 23.7446L11.9241 22.8295H12.8392V23.7446ZM18.9457 24.5414H13.9666V24.5379V22.2623C13.9666 21.9492 13.7126 21.7021 13.4064 21.7021H11.1308V9.48223C11.1308 9.28739 11.2874 9.12383 11.4892 9.12383H22.8253C23.0202 9.12383 23.1837 9.2804 23.1837 9.48223V17.4259C22.5505 17.0083 21.7989 16.7683 20.9882 16.7683C18.7718 16.7683 16.9694 18.5706 16.9694 20.7871C16.9694 22.2554 17.7627 23.5393 18.9457 24.2387V24.5414ZM21.9067 26.4307H21.9032L21.3639 25.9436C21.1517 25.7522 20.8281 25.7522 20.6123 25.9436L20.073 26.4307V24.691C20.3688 24.7606 20.675 24.7989 20.9881 24.7989C21.3048 24.7989 21.611 24.7571 21.9067 24.691V26.4307ZM20.9812 23.6785C19.3876 23.6785 18.0898 22.3807 18.0898 20.7871C18.0898 19.1935 19.3876 17.8956 20.9812 17.8956C22.5748 17.8956 23.8726 19.1935 23.8726 20.7871C23.8726 22.3807 22.5783 23.6785 20.9812 23.6785Z" fill={isActive ? "#0071BC" : "#929292"}/>
        </svg>

    );
};

Icon.propTypes = {
    isActive: PropTypes.bool
};

export default Icon;
