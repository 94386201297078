/* eslint-disable no-nested-ternary */
/* eslint-disable max-statements */
/* eslint-disable complexity */
import React, {  memo, Fragment, useEffect } from "react";
import PropTypes from "prop-types";

// Styles
import styles from "./styles.css";

// Images
import HomeIcon from "./svg/HomeIcon";
import BuyCarIcon from "./svg/CarBuyIcon";
import SellCarIcon from "./svg/SellCarIcon";
import RwcIcon from "./svg/RwcIcon";

import FinanceIcon from "./svg/FinanceIcon";
import BottomNavigationIndicator from "../../shared/images/icons/bottom-navigation-indicatior.svg";

// Utils
import Filters from "../../../utils/filters-v2";
import { getItemFromSession, setItemInSession } from "../../../utils/helpers/session-storage";

// Constants
import { DEFAULT_CITY, SESSION_KEYS } from "../../../constants/app-constants";

// Hooks
import useScrollDirection from "../../../hooks/scroll-direction";

// Tracking
import { AU_MOBILE_EVENTS } from "../../../tracking/au.mobile-events";
import { trackMobileCustomEventsAU } from "../../../tracking";

import TrackImpression from "../../shared/track-impression";
import { yieldToMain } from "../../../utils/helpers/yield-to-main";
import useQueryParams from "../../../hooks/use-queryPrams";
import { RWC_GA_EVENTS } from "../rwc-landing-page/tracking";
import useCustomHistoryOperations from "../../../hooks/use-custom-history-operation";
import { scrollToTop } from "../../../utils/scroll-to-top";
import routesConstant from "../../../constants/routes-constant";

export const BOTTOM_NAV_ROUTES = {
    HOME: "au.home",
    LISTING: "au.listing",
    SELL_CAR: "c2b-trade",
    FINANCE_PAGE: "finance",
    RWC_PAGE: "au.roadworthy-certificate"
};

export const BOTTOM_NAV_ROUTES_CONFIG = {
    HOME: "/",
    SELL_CAR: "/sell-your-car/",
    FINANCE_PAGE: "/cars24-finance/",
    RWC_PAGE: routesConstant.roadworthy_certificate_default
};
const BottomNavigation = ({
    routeName,
    allFilters,
    selectedFilters,
    selectedCity,
    hideBottomNavOnScroll,
    gaId = ""
}) => {
    const [isBottomIconAnimationVisible, setBottomIconAnimationVisible] = React.useState(false);
    const scrolledUp = useScrollDirection({ threshold: 10 });
    const queryParams = useQueryParams();
    const gaData = {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "sell_animation_bottom_nav"
    };
    const { customPush } = useCustomHistoryOperations();

    const handleRedirection = async (newRoute) => {
        if (newRoute === routeName) return;
        // let amplitudeEvent = null;
        let gaEventLabel = null;
        let redirectionUrl = null;
        if (newRoute === BOTTOM_NAV_ROUTES.HOME) {
            redirectionUrl = BOTTOM_NAV_ROUTES_CONFIG.HOME;
            gaEventLabel = "home";
        } else if (newRoute === BOTTOM_NAV_ROUTES.FINANCE_PAGE) {
            redirectionUrl = BOTTOM_NAV_ROUTES_CONFIG.FINANCE_PAGE;
            await yieldToMain();
            trackMobileCustomEventsAU(AU_MOBILE_EVENTS.FF_VOLUME_EXPERIMENT, {
                eventAction: "Bottom_nav_finance",
                eventLabel: "Bottom_nav_finance_clicked"
            });

        } else if (newRoute === BOTTOM_NAV_ROUTES.RWC_PAGE) {
            redirectionUrl = BOTTOM_NAV_ROUTES_CONFIG.RWC_PAGE;
            trackMobileCustomEventsAU(RWC_GA_EVENTS.RWC_SECTION_ENTERED, {
                ...RWC_GA_EVENTS.RWC_SECTION_ENTERED,
                eventLabel: "bottom_nav_bar"
            });
            gaEventLabel = "rwc";
        } else {
            redirectionUrl = BOTTOM_NAV_ROUTES_CONFIG.SELL_CAR;
            gaEventLabel = `sell_car_${gaId}`;
        }

        const {fc} = queryParams || {};
        if (fc) {
            redirectionUrl = `${redirectionUrl}?fc=${fc}`;
        }

        const mobileBottomAnimationClicked = getItemFromSession(SESSION_KEYS.MOBILE_BOTTOM_ANIMATION_CLICKED);
        if (mobileBottomAnimationClicked === "false" && newRoute === BOTTOM_NAV_ROUTES.FINANCE_PAGE) {
            setItemInSession(SESSION_KEYS.MOBILE_BOTTOM_ANIMATION_CLICKED, true);
            setBottomIconAnimationVisible(false);
        }
        customPush(redirectionUrl);
        await yieldToMain();
        trackMobileCustomEventsAU(AU_MOBILE_EVENTS.BOTTOM_NAVIGATION, {
            eventLabel: gaEventLabel
        });
        scrollToTop();
    };

    const onBuyCarCLick = async() => {
        if (routeName === BOTTOM_NAV_ROUTES.LISTING) return;
        const filters = new Filters({cityCode: selectedCity.code || DEFAULT_CITY.AU.code});
        const {relativeURL} = filters.getListingPayload({selectedFilters, filters: allFilters});
        customPush(relativeURL);
        await yieldToMain();
        trackMobileCustomEventsAU(AU_MOBILE_EVENTS.BOTTOM_NAVIGATION, {
            eventLabel: "buy_car"
        });

    };

    const handleSellCar = () => {
        handleRedirection(BOTTOM_NAV_ROUTES.SELL_CAR);
    };

    useEffect(() => {
        const mobileBottomAnimationClicked = getItemFromSession(SESSION_KEYS.MOBILE_BOTTOM_ANIMATION_CLICKED);
        if (!mobileBottomAnimationClicked || mobileBottomAnimationClicked === "false") {
            setItemInSession(SESSION_KEYS.MOBILE_BOTTOM_ANIMATION_CLICKED, "false");
            setBottomIconAnimationVisible(true);
        }
    }, []);

    return (
        <Fragment>
            <div styleName={`styles.fixed ${(scrolledUp && hideBottomNavOnScroll) ? "" : hideBottomNavOnScroll ? "styles.bottomNavWrapHide" : ""}`}  >
                <div onClick={() => handleRedirection(BOTTOM_NAV_ROUTES.HOME)} styleName={`styles.iconWrapper ${BOTTOM_NAV_ROUTES.HOME ===  routeName  ? "styles.active" : ""}`}>
                    <span><HomeIcon isActive={BOTTOM_NAV_ROUTES.HOME ===  routeName}/></span>
                    <p styleName={"styles.tabName"}>Home</p>
                </div>
                <div onClick={handleSellCar} styleName={`styles.iconWrapper ${BOTTOM_NAV_ROUTES.SELL_CAR ===  routeName ? "styles.active" : ""}`}>
                    {BOTTOM_NAV_ROUTES.SELL_CAR ===  routeName && <span><SellCarIcon isActive/></span>}
                    {BOTTOM_NAV_ROUTES.SELL_CAR !== routeName && (
                        <TrackImpression event={{
                            gaName: AU_MOBILE_EVENTS.SELL_ANIMATION,
                            data: {eventLabel: gaId, ...gaData}
                        }}>
                            <span><SellCarIcon/></span>
                        </TrackImpression>
                    )}
                    <p styleName={"styles.tabName"}>Sell car</p>
                </div>
                <div onClick={onBuyCarCLick} styleName={`styles.iconWrapper ${BOTTOM_NAV_ROUTES.LISTING ===  routeName  ? "styles.active" : ""}`} >
                    <span><BuyCarIcon isActive={BOTTOM_NAV_ROUTES.LISTING ===  routeName}/></span>
                    <p styleName={"styles.tabName"}>Buy car</p>
                </div>
                <div onClick={() => handleRedirection(BOTTOM_NAV_ROUTES.FINANCE_PAGE)} styleName={`styles.iconWrapper ${BOTTOM_NAV_ROUTES.FINANCE_PAGE ===  routeName ? "styles.active" : ""}`}>
                    {<span>
                        <FinanceIcon isActive={BOTTOM_NAV_ROUTES.FINANCE_PAGE ===  routeName}/>
                    </span>}

                    <p styleName={"styles.tabName"}>Finance</p>
                </div>
                <div onClick={() => handleRedirection(BOTTOM_NAV_ROUTES.RWC_PAGE)} styleName={`styles.iconWrapper ${BOTTOM_NAV_ROUTES.RWC_PAGE ===  routeName ? "styles.active" : ""}`}>
                    {

                        isBottomIconAnimationVisible ? <div styleName={"styles.animationCont"}>
                            <RwcIcon isActive={BOTTOM_NAV_ROUTES.RWC_PAGE ===  routeName}/>
                            <span styleName={"styles.animationWrap"}>
                                <img src={BottomNavigationIndicator} alt="animation" width="32" height="32" />
                            </span>
                        </div> : <span>
                            <RwcIcon isActive={BOTTOM_NAV_ROUTES.RWC_PAGE ===  routeName}/>
                        </span>
                    }
                    <p styleName={"styles.tabName"}>RWC</p>
                </div>

            </div>
        </Fragment>
    );
};

BottomNavigation.propTypes = {
    isActive: PropTypes.bool,
    routeName: PropTypes.string,
    selectedFilters: PropTypes.object,
    selectedCity: PropTypes.object,
    allFilters: PropTypes.object,
    hideBottomNavOnScroll: PropTypes.bool,
    gaId: PropTypes.string
};

export default memo(BottomNavigation);
