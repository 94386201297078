import React from "react";
import PropTypes from "prop-types";

const Icon = ({isActive = false}) => {
    return (
        <svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill={isActive ? "#06C" : "#929292"} fill-rule="evenodd" clip-rule="evenodd" d="M13.72 7.706a.43.43 0 0 1 .41-.114l3.277.829h2.568c.153 0 .294.082.37.214a.42.42 0 0 1-.006.424l-1.125 1.858c.406.322.851.755 1.302 1.253a22 22 0 0 1 1.91 2.465c.606.907 1.158 1.874 1.56 2.804.399.923.664 1.84.664 2.636 0 1.817-.648 3.374-2.025 4.465-1.364 1.08-3.388 1.66-6.065 1.66-2.673 0-4.5-.58-5.654-1.687-1.159-1.111-1.556-2.672-1.556-4.438 0-1.543.861-3.623 1.912-5.419a19 19 0 0 1 1.704-2.47c.479-.582.965-1.079 1.416-1.414l-.766-2.656a.42.42 0 0 1 .104-.41m5.504 1.557-.765 1.263h-3.263l-.559-1.937 2.61.661q.052.013.106.013zm-4.223 2.105c-.387.263-.865.729-1.376 1.35a18.4 18.4 0 0 0-1.628 2.36c-1.036 1.771-1.797 3.688-1.797 4.997 0 1.657.374 2.948 1.297 3.833.927.89 2.492 1.45 5.063 1.45 2.568 0 4.376-.56 5.534-1.476 1.144-.906 1.706-2.202 1.706-3.807 0-.63-.215-1.426-.595-2.304-.377-.872-.902-1.795-1.488-2.671a21 21 0 0 0-1.834-2.368c-.565-.624-1.081-1.096-1.482-1.364z" />
            <path fill={isActive ? "#06C" : "#929292"} d="M19.474 16.42c.002.014-.004.035-.027.056a.14.14 0 0 1-.094.036h-1.102c-.064 0-.101-.034-.111-.065-.088-.35-.44-.595-.843-.595h-.98c-.606 0-1.192.371-1.262.939-.083.64.497 1.165 1.189 1.165h.832c1.499 0 2.671 1.122 2.533 2.42-.116.995-1.047 1.768-2.192 1.934l-.086.012v1.065c0 .039-.04.092-.12.092h-1.102c-.08 0-.12-.053-.12-.092v-1.051l-.095-.006c-1.088-.061-1.945-.789-2.048-1.698-.002-.014.004-.035.027-.056a.14.14 0 0 1 .094-.035h1.101c.065 0 .102.033.112.064.088.35.44.595.843.595h.98c.605 0 1.192-.37 1.262-.938.083-.64-.497-1.166-1.189-1.166h-.722c-1.338 0-2.515-.85-2.643-1.974-.127-1.198.859-2.235 2.19-2.39l.088-.01v-1.057c0-.038.04-.091.12-.091h1.102c.08 0 .12.053.12.091v1.052l.095.005c1.088.062 1.945.79 2.048 1.699Z"  stroke="#fff" stroke-width=".2"/>
        </svg>

    );
};

Icon.propTypes = {
    isActive: PropTypes.bool
};

export default Icon;
